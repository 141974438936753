
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '@app/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MonCompteService} from '@app/services/mon-compte.service';
import {Subject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'mytribu-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss'],

})
export class AccueilComponent implements OnInit {
  

  public slider_header: any = [];
  pages: any;
  name: any;
  private _unsubscribeAll: Subject<any> = new Subject();
  orderId: any;
  contenu: any = [];
  url: any = environment.urlImage;
  urlPage :any;
  showIndicator = false;
  itemsPerSlide :any;
  singleSlideOffset = true;
  noWrap :any;
  interval :any;
  scrWidth:any;
  activePane : 'right'
  slider_avantages = [
    {
      title: 'Parc de loisirs',
      image: 'assets/images/avantages/parc.png',
      id_block: 'parc'
    },
    {
      title: 'Prêt-à-porter',
      image: 'assets/images/avantages/pretaporter.png',
      id_block: 'pretaporter'
    },
    {
      title: 'Sports & Loisirs',
      image: 'assets/images/avantages/sports.png',
      id_block: 'sports'
    },
    {
      title: 'Transports',
      image: 'assets/images/avantages/transport.png',
      id_block: 'transport'
    },
    {
      title: 'Bien-être',
      image: 'assets/images/avantages/bienetre.png',
      id_block: 'bienetre'
    },
    {title: 'Cinéma',
      image: 'assets/images/avantages/cinema.png',
      id_block: 'cinema'
    },
    {
      title: 'Concerts & Spectacles',
      image: 'assets/images/avantages/concerts.png',
      id_block: 'concerts'
    },
    {
      title: 'Vie quotidienne',
      image: 'assets/images/avantages/viequotidienne.png',
      id_block: 'viequotidienne'
    },
    {
      title: 'Destination vacances',
      image: 'assets/images/avantages/vacances.png',
      id_block: 'vacances'
    },
    {
      title: 'Location',
      image: 'assets/images/avantages/location.png',
      id_block: 'location'
    }
  ];

  carouselOptions = {
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    rtl: true
  };
  constructor(public auth: AuthService, public activeRoute: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.getSlider();
    this.getDynamicPage()
    var bodyColor = "bodyColorWhite"
    document.getElementsByTagName('body')[0].className=bodyColor;
    console.log(document.getElementsByTagName('body')[0].className)
    if (window.screen.width < 767){
      this.itemsPerSlide = 1;
      this.noWrap=false;
      this.interval=10000;
    }else {
      this.itemsPerSlide = 4;
      this.noWrap=true;
      this.interval=0;
    }
    this.getScreenSize();
  }
  ngOnDestroy() {
    // remove the the body classes
    var bodyColor = "bodyColor"
    document.getElementsByTagName('body')[0].className=bodyColor;
  }
  getSlider() {
    this.slider_header = [];
    this.auth
      .getSlider()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.slider_header = res;
      });
  }
  /*navigateGeneriquePage(item,block) {
    localStorage.setItem('pages', JSON.stringify(item.segment+block));
    console.log(JSON.stringify(item.segment+block))
    this.urlPage=JSON.stringify(item.segment+block);
    this.router.navigate(['/home/' + item.segment+block]);
  }
  navigateGeneriquePage(item, block) {
    localStorage.setItem("pages", JSON.stringify(item.segment + '#' + block));
    console.log(JSON.stringify(item.segment + block));
    this.urlPage = JSON.stringify(item.segment + block);
    // this.router.navigate(["/home/" + item.segment + block]);
    this.router.navigate(['/home/' + item.segment], { fragment: block });
  }
   navigateGeneriquePage(item,block){

    console.log(item)
    localStorage.setItem('pages', JSON.stringify(item.segment + '#' + block));
    this.router.navigate(['/home/' + item.segment+ '#' + block]);
    
  }*/
  navigateGeneriquePage(item){

    console.log(item)
    localStorage.setItem('pages', JSON.stringify(item));
    this.router.navigate(['/home/' + item.segment]);
    
  }

  getDynamicPage() {
    this.auth
      .StaticPageDynamic()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(res => {
        this.pages= res   
      })
  }

  navigate(url) {
    localStorage.setItem('pages', JSON.stringify(url));
    this.router.navigate(['/accueil/' + url]);
  }

  navigateSecteur(urlSecteur){
    this.router.navigate(['/sigin'], {queryParams:{data:urlSecteur}});
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
        console.log(this.scrWidth, 'xidth', event)
        if (this.scrWidth < 767){
          this.itemsPerSlide = 1;
        } else {
          this.itemsPerSlide = 4;
        }
        console.log(this.itemsPerSlide , 'this.itemsPerSlide')
        return this.itemsPerSlide;
  }
}
