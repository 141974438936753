import { Component, OnInit, Inject, HostListener } from '@angular/core'
import { DOCUMENT } from '@angular/common' // le nouveau package de la classe DOCUMENT

@Component({
  selector: 'mytribu-scroll',
  templateUrl: './scroll.component.html',
  styleUrls: ['./scroll.component.scss']
})
export class ScrollComponent implements OnInit {
  windowScrolled: any
  showScrollHeight = 300
  hideScrollHeight = 10
  constructor(@Inject(DOCUMENT) private document: Document) {}
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (localStorage.position !==''){
      let pos = Math.floor(localStorage.position) * 400 + 350;
      document.documentElement.scrollTop = pos;
      localStorage.setItem('position', '');
    }else{
      
      if (
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > this.showScrollHeight
      ) {
        this.windowScrolled = true
      } else if (
        (this.windowScrolled && window.pageYOffset) ||
        document.documentElement.scrollTop ||
        document.body.scrollTop < this.hideScrollHeight
      ) {
        this.windowScrolled = false
      }
    }
  }
  scrollToTop() {
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
      });
    
  }

  ngOnInit() {}
}
