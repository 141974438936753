import { Component, OnInit, Input, Output, EventEmitter,TemplateRef,HostListener  } from '@angular/core'
import { AuthService } from '@app/services/auth.service'
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MonCompteService } from '@app/services/mon-compte.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'mytribu-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() connect = new EventEmitter();
  showPass = false;
  noWrap = true; 
  resetpassword: any = false;

  modalRef?: BsModalRef;
  client: any = {
    login: "",
    password: ""
  };
  resetPass = {
    email: ""
  };
  sendMail = false;
  click = false;
  errormessage: any = "";
  errorPass: any = "";
  isCollapsed = false;
  clientIdentifiant: any;
  orderId: any;
  reponseManuel: any;
  successPass: any = "";
  showPassword: any = false;
  contenu: any = [];
  addpassword: any = false;
  id: any;
  idPass: any;
  secteur: string = 'salarie';
  //modalRef?: BsModalRef | null;
  
  constructor(public auth: AuthService, public router: Router,private modalService: BsModalService,public monCompte: MonCompteService,private cookies: CookieService) {}

  item: any = 1;
  openMenu = true;
  pages:any
  name: any
  private _unsubscribeAll: Subject<any> = new Subject()
  ngOnInit() {
    this.getDynamicPage()
  }
  goto(id) {
    this.connect.emit({ id: this.item });
    this.router.navigate(['connexion']);
  }
  navigate(url) {
    localStorage.setItem('pages', JSON.stringify(url));
    this.router.navigate(['/home/' + url]);
  }

  nivigate(url) {
    console.log('/home/' + url)
    localStorage.setItem('pages', JSON.stringify(url));
    this.router.navigate(['/home/' + url]);
  }


  navigateGeneriquePage(item){

    console.log(item)
    localStorage.setItem('pages', JSON.stringify(item));
    this.router.navigate(['/home/' + item.segment]);
    
  }
  getDynamicPage() {
    this.auth
    .StaticPageDynamic()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(res => {

      console.log(res)
      this.pages= res      
    })
}
  openModalWithClass(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'container gray modal-xl' })
    );
  }

 RedirectInscrit(){
    this.modalRef.hide();
    this.router.navigate(["inscription"]);
  }

 
  /*** Login Client */
  LoginClient(myform) {
    // localStorage.clear()
    console.log('this.client',this.client)
    console.log('form',myform)
    if (myform.valid == true) {
      this.errormessage = "";
      if (this.client.login != "" && this.client.password != "") {
        this.auth
          .LoginClient(this.client)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            res => {
              this.auth.setAuth(res.token);
              this.auth.setClient(res.user);
              this.errormessage = "";
              localStorage.setItem("dossierExpired", res.user.dossierExpired);
              this.clientIdentifiant = res.user.cleAdhesion;
              localStorage.setItem("clientIdentifiant", this.clientIdentifiant);
              if (localStorage.getItem("clientNonInscri")) {
                localStorage.removeItem("clientNonInscri");
              }
              //this.cookies.deleteAll();
              this.cookies.delete('clientIdentifiant');
              this.cookies.set("clientIdentifiant", this.clientIdentifiant);
              console.log("clientIdentifiant");
              console.log( this.cookies.get("clientIdentifiant"));
              this.router.navigate(["home"], {
                queryParams: { modalConnexion: "true" }
              });
              this.modalRef.hide();
            },
            MsgError => {
              if (
                MsgError.err.error &&
                MsgError.err.error.message == "Mot de passe introuvable."
              ) {
                this.errormessage = "Mot de passe incorrect.";
              } else if (
                MsgError.err.error &&
                MsgError.err.error.message == "login non trouvé."
              ) {
                this.errormessage = "Nom d'utilisateur incorrect.";
              }
              this.router.navigate(["connexion"]);
              this.modalRef.hide()
            }
          );
      } else {
        this.errormessage =
          this.client.login == ""
            ? "Email où Clé d'adhésion obligatoire"
            : "Mot de passe obligatoire";
      }
    }
  }
  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
    /*** Reset Password */
    Resetpassword() {
      this.click = true;
      if (this.resetPass.email !== "") {
        this.monCompte
          .ResetPassword(this.resetPass)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            res => {
              this.errorPass = "";
              this.successPass = "Email envoyée avec succès.";
              let self = this;
              setTimeout(() => {
                self.successPass = "";
              }, 5000);
            },
            resError => {
              if (
                (resError.err.error &&
                  resError.err.error.message == "Email incorrecte.") ||
                resError.err.error.message ==
                  "Certains champs sont invalides : email"
              ) {
                this.errorPass = "Adresse email inconnue";
              }
            }
          );
      } else {
        this.errorPass =
          this.resetPass.email == "" ? "Ce Champ est obligatoire" : "";
      }
    }

   
 /**
   * open & close modal MonCompte
   */
 openModalMonCompte(template: TemplateRef<any>) {
  localStorage.clear();
  this.router.navigate([""]);
  this.modalRef = this.modalService.show(
    template,
    Object.assign({ id: 1 }, { class: "container gray modal-xl " })
  );
}
closeModalMoncompte() {
  if (!this.modalRef) {
    return;
  }
  this.modalRef.hide();
  this.modalRef = null;
}
}