import { Component } from '@angular/core'
import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd
} from '@angular/router'
import { SplashScreenService } from './services/splash-screen.service'
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'myTribu'
  public isShowingRouteLoadIndicator: boolean
  location: any = ''

  /**
   * Constructor
   *
   * @param {SplashScreenService} SplashScreenService
   */
  constructor(
    public router: Router,

    // tslint:disable-next-line:no-shadowed-variable
    private SplashScreenService: SplashScreenService,@Inject(DOCUMENT) private document: Document
  ) {
    this.currentLocation()
  }
  /* Detect navigation and change location */
  currentLocation() {
    let asyncLoadCount = 0;
    this.router.events.subscribe(
      (event: any): void => {
        /*   routerLink  */
        if (event.url) {
          if (!event.error && event.url.indexOf('(') === -1) {
            this.location = '/' + event.url.split('/')[1]
          }
        }
        /* loading page */
        if (event instanceof RouteConfigLoadStart) {
          asyncLoadCount++
        } else if (event instanceof RouteConfigLoadEnd) {
          asyncLoadCount--
        }
        this.isShowingRouteLoadIndicator = !!asyncLoadCount
      }
    )
  }
  // tab: number
  ngOnInit() {
    
    
  }
  /*scrollToTop(): void {
    // scroll to the top of the body
    return this.document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }*/
}
